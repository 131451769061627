import React from 'react'
import { Box, TextInput, FormField, Select, Text } from 'grommet'
import colours from '../util/colours'

import { COUNTRIES } from '../constants/countries'

import { changeDialCode, changePhoneNumber } from '../actions/user'

import { connect } from 'react-redux'

const stripMatch = (text, input) => {
  input = input.replace(/[+| ]/g,'').toUpperCase()
  text = text.replace(/[+| ]/g,'').toUpperCase()
  return text.startsWith(input)
}

const mapStateToProps = state => ({
  dialCode: state.signup.dialCode,
  phoneNumber: state.signup.phoneNumber,
})

const mapDispatchToProps = dispatch => ({
  onDialCodeChange: val => dispatch(changeDialCode(val)),
  onPhoneNumberChange: val => dispatch(changePhoneNumber(val)),
})

class PhoneNumber extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedCountry: COUNTRIES.find(el => el.code === 'US'),
      countries: COUNTRIES
    }
  }

  render() {
    return (
      <Box>
        <Box
          direction='row'
          pad={{left:"small", top:"medium", bottom:"small"}}
        >
          <Text size='small' color='dark-6'>
            Secure your data with your Phone Number (Optional)
          </Text>
        </Box>
        <Box
          direction='row'
          gap='medium'
        >
          <Box
            basis = "1/3"
            justify='end'
          >
            <FormField>
              <Select
                value={this.state.selectedCountry}
                multiple={false}
                dropAlign={{ top:"bottom"}}
                dropHeight="medium"
                searchPlaceholder="Search.."
                valueLabel={(
                  <Box pad="small">
                    {this.state.selectedCountry.code + ' ' + this.state.selectedCountry.dial_code}
                  </Box>
                )}
                labelKey={option => option.name + ' ' + option.dial_code}
                options= {this.state.countries}
                onChange={({ option }) => {
                  this.setState({
                    selectedCountry: option,
                  })
                  this.props.onDialCodeChange(option.dial_code)
                }}
                onSearch= {str => {
                  this.setState({
                    countries: COUNTRIES.filter(el => {
                      return (
                        stripMatch(el.dial_code + el.name, str)
                        || stripMatch(el.name + el.dial_code, str)
                        || stripMatch(el.dial_code + el.code, str)
                        || stripMatch(el.code + el.dial_code, str)
                      )
                    })
                  })
                }}
              />
            </FormField>
          </Box>
          <Box
            basis='2/3'
            justify='end'
          >
            <FormField>
              <TextInput
                onChange={evt => this.props.onPhoneNumberChange(evt.target.value)}
                value={this.props.phoneNumber}
              />
            </FormField>
          </Box>
        </Box>
      </Box>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhoneNumber)
