import React from 'react'
import { parse } from 'qs'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import { Anchor, Box, Button, Heading, FormField, Text, TextInput, RadioButton, Select, Form } from 'grommet'
import { Alert, Info } from 'grommet-icons'

import { changeEmail, changePassword, changeFirstName, changeLastName, changeSex, changeDOB, signup, changeDnaTested, changeReferralCode } from '../actions/user'
import AppLayout from '../components/app-layout'
import PhoneNumber from '../components/phone-number'

export const TESTED_OPTIONS = {
  not_tested: 'I\'m not tested',
  '23andme': '23andMe',
  ancestry: 'Ancestry',
  family_tree: 'Family Tree DNA',
  living_dna: 'Living DNA',
  myheritage: 'MyHeritage',
  other: 'Other',
}

const URL_TESTED_OPTION_MAP = {
  '23andMe': '23andme',
  'AncestryDNA': 'ancestry',
  'MyHeritage': 'myheritage',
  'Family Tree DNA': 'family_tree',
  'Living DNA': 'living_dna',
  'Other': 'other',
  'Not Tested': 'not_tested',
}

const mapStateToProps = state => ({
  email: state.signup.email,
  password: state.signup.password,
  firstName: state.signup.firstName,
  lastName: state.signup.lastName,
  dnaTested: state.signup.dnaTested,
  sex: state.signup.sex,
  dob: state.signup.dob,
  ready: (
    state.signup.email &&
    state.signup.password &&
    state.signup.firstName &&
    state.signup.lastName &&
    state.signup.sex &&
    state.signup.dob &&
    state.signup.dnaTested
  ),
  loading: state.signup.loading,
  error: state.signup.error,
  referralCode: state.signup.referralCode
})

const mapDispatchToProps = dispatch => ({
  onEmailChange: val => dispatch(changeEmail(val)),
  onPasswordChange: val => dispatch(changePassword(val)),
  onFirstNameChange: val => dispatch(changeFirstName(val)),
  onLastNameChange: val => dispatch(changeLastName(val)),
  onSexChange: val => dispatch(changeSex(val)),
  onDOBChange: val => dispatch(changeDOB(val)),
  onTestedChange: val => dispatch(changeDnaTested(val)),
  onSignup: (email, password) => dispatch(signup(email, password)),
  onReferralCode: referralCode => dispatch(changeReferralCode(referralCode))
})

const SignupPage = ({
  email,
  password,
  firstName,
  lastName,
  dnaTested,
  sex,
  dob,
  ready,
  error,
  loading,
  location,
  onEmailChange,
  onPasswordChange,
  onFirstNameChange,
  onLastNameChange,
  onSexChange,
  onDOBChange,
  onTestedChange,
  onSignup,
  onReferralCode,
  referralCode
}) => {
  React.useEffect(() => {
    const params = parse(location.search.replace(/\?/g, ''))
    console.log({params})
    if (params.referralCode) {
      onReferralCode(params.referralCode)
    }
    if (params.email) {
      onEmailChange(params.email)
    }
    if (params.dnaTested) {
      onTestedChange(URL_TESTED_OPTION_MAP[params.dnaTested])
    }
  }, [])
  return (
    <AppLayout page="signup">
      <Box
        style={{ maxWidth: '64rem', marginLeft: 'auto', marginRight: 'auto' }}
        fill={true}
        pad={{left:'xlarge', top:'medium', bottom:'xlarge', right:'xlarge'}}
      >
        <Heading color='accent-2'>
          tell us a bit about yourself
        </Heading>
        <Form
          onSubmit={ready && !loading ? () => onSignup(email, password) : null}
        >
          <Box
            gap='medium'
            pad={{ top: 'medium' }}
          >
            <Box
              direction='row-responsive'
              gap='medium'
            >
              <Box
                basis='1/2'
              >
                <FormField
                  label='Email*'
                >
                  <TextInput
                    onChange={evt => onEmailChange(evt.target.value)}
                    value={email}
                  />
                </FormField>
              </Box>
              <Box
                basis='1/2'
              >
                <FormField
                  label='Password*'
                >
                  <TextInput
                    type='password'
                    onChange={evt => onPasswordChange(evt.target.value)}
                    value={password}
                  />
                </FormField>
              </Box>
            </Box>
            <Box
              direction='row-responsive'
              gap='medium'
            >
              <Box
                basis='1/2'
              >
                <FormField
                  label='First Name*'
                >
                  <TextInput
                    onChange={evt => onFirstNameChange(evt.target.value)}
                    value={firstName}
                  />
                </FormField>
              </Box>
              <Box
                basis='1/2'
              >
                <FormField
                  label='Last Name*'
                >
                  <TextInput
                    onChange={evt => onLastNameChange(evt.target.value)}
                    value={lastName}
                  />
                </FormField>
              </Box>
            </Box>
            
            <Box
              background='status-warning'
              align='center'
              direction='row'
              pad='small'
              gap='small'
              round='xsmall'
            >
              <Info size='small' color='white' />
              <Text size='xsmall'>
                Your <strong>Gender at Birth</strong> and <strong>Date of Birth</strong> will only be used to calculate your&nbsp;
                <Anchor
                  href='https://ods.od.nih.gov/Health_Information/Dietary_Reference_Intakes.aspx'
                  target='_blank'
                >
                  Recommended Dietary Allowance (RDA)
                </Anchor>.
              </Text>
            </Box>
            <Box
              direction='row-responsive'
              gap='medium'
            >
              <Box
                basis='1/2'
                pad={{ horizontal: 'small', vertical: 'xsmall' }}
              >
                <Text
                  size='small'
                  margin={{ bottom: 'small' }}
                >
                  Gender at Birth*<br />
                </Text>
                <Box
                  direction='row'
                  pad={{ top: 'xsmall' }}
                  gap='large'
                >
                  <RadioButton
                    name='sex'
                    label='Male'
                    onChange={evt => onSexChange(evt.target.value)}
                    value='male'
                    checked={sex === 'male'}
                  />
                  <RadioButton
                    name='sex'
                    label='Female'
                    onChange={evt => onSexChange(evt.target.value)}
                    value='female'
                    checked={sex === 'female'}
                  />
                </Box>
              </Box>
              <Box
                basis='1/2'
              >
                <FormField
                  label='Date of Birth*'
                >
                  <TextInput
                    type='date'
                    max={new Date().toISOString().substr(0, 10)}
                    onChange={evt => onDOBChange(evt.target.value)}
                    placeholder='yyyy-mm-dd'
                    value={dob}
                  />
                </FormField>
              </Box>
            </Box>
            <Box
              direction = "row-responsive"
              gap= "medium"
            >
              <Box
                basis="1/2"
                justify="between"
              >
                <Box
                  pad={{left:"small", top:"medium", bottom:"small"}}
                >
                  <Text size='small' color='dark-6'>
                    Have you been DNA tested?*
                  </Text>
                </Box>
                <FormField>
                  <Select
                    value={dnaTested}
                    valueLabel={(
                      <Box pad="small">
                        {TESTED_OPTIONS[dnaTested]}
                      </Box>
                    )}
                    valueKey="id"
                    labelKey="label"
                    options={Object.entries(TESTED_OPTIONS).map(([k, v]) => ({ id: k, label: v }))}
                    onChange={({ option }) => onTestedChange(option.id)}
                  />
                </FormField>
              </Box>
              <Box
                basis="1/2" 
              >
                <PhoneNumber />
              </Box>
            </Box>
            <FormField>
                  <Text size='small' color='dark-6'>
                    Referral Code
                  </Text>
                <TextInput
                  onChange={evt => onReferralCode(evt.target.value)}
                  value={referralCode}
                  placeholder="gini-sandy-a231"
                />
              </FormField> 
          </Box>
        </Form>
        {
          error &&
          <Box
            background='status-error'
            align='center'
            direction='row'
            pad='small'
            gap='small'
            round='xsmall'
          >
            <Alert size='small' color='white' />
            <Text size='small' color='white'>{ error }</Text>
          </Box>
        }
        <Box
          direction='row'
          align='center'
          pad={{ top: 'xlarge' }}
          justify='between'
        >
          <Link style={{ marginBottom: '1rem' }} to='/signin'>
            <Text
              style={{ textDecoration: 'underline' }}
              color='accent-2'
              textAlign='center'
              size='small'
            >
              Already have account?
            </Text>
          </Link>
          <Button
            onClick={ready && !loading ? () => onSignup(email, password) : null}
            label={loading ? 'One sec...' : 'Create an account'}
            color='accent-1'
            primary
          />
        </Box>
      </Box>
    </AppLayout>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupPage)
